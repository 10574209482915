import React, { FC, useEffect, useState } from "react";
import {getCurrentCustomer, getCurrentUser, getWebsiteUser} from "./fetchers";
import { Link, Redirect, Route, Switch } from "wouter";
import { useDispatch } from "react-redux";
import {
  Apply,
  Documents,
  Home,
  News,
  RequiredClasses,
  ScheduledClasses,
  WorkSchedules,
} from "./components";
import {
  Loading,
} from 'shared/src/components';
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import {setCurrentCustomer, setCurrentPollworkerUser} from "./state/actions";
import Profile from "./components/Profile";
import { LOCAL_STORAGE_KEYS } from "./constants";
import { useTimeoutFn, useTitle } from "react-use";
import AvailabilitySurvey from "./components/AvailabilitySurvey";
import useIsLoggedIn from "./components/selectors/useIsLoggedIn";
import { SectionSubHeading, SectionSubSubHeading } from "shared/src/components/SectionHeading";
import Registration from "./components/Registration";
import * as SentrySetup from 'shared/src/setupSentry';
import * as Sentry from '@sentry/react';
import { Button } from "shared/src/components/ui";
import PollworkerTimeclock from "./components/PollworkerTimeclock";

export default function App() {
  const [showApp, setShowApp] = useState<boolean>();
  const [showReload, setShowReload] = useState(false);
  const loggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  useTitle('EasyVote Pollworker');

  useTimeoutFn(() => {
    Sentry.captureMessage('Long load, showing reload button');
    setShowReload(true);
  }, 5000);

  useEffect(() => {
    handleInit();
  }, []);

  async function handleInit() {
    try {
      const websiteUserResp = await getWebsiteUser();
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.WEBSITE_USER, JSON.stringify(websiteUserResp));

      const currentCustomerResp = await getCurrentCustomer();
      dispatch(setCurrentCustomer(currentCustomerResp));

      const currentUserId = window.localStorage.getItem('CurrentUser');

      if (currentUserId) {
        const currentUserResp = await getCurrentUser(currentUserId);
        SentrySetup.identifyLoggedInUser(currentUserResp.EVUserId, currentUserResp.UserId, '', currentUserResp.Customer.Id, currentUserResp.Customer.Name, currentUserResp.Customer.State);
        dispatch(setCurrentPollworkerUser(currentUserResp));
      }

      setShowApp(true);
    } catch (e) {
      setShowReload(true);
      setShowApp(false);
      Sentry.captureException(e);
    }
  }

  function protectRoute(component: FC): FC {
    if (loggedIn) return component;

    return () => <Redirect to='/login' />;
  }

  if (!showApp) {
    return (
      <div className="min-h-96 w-full h-full flex flex-col justify-center space-y-4">
        <Loading loadingMessage={showReload ? 'Still loading...' : 'Please wait...'} />
        {
          showReload ? (
            <span className='mx-auto'>
              <Button onClick={() => window.location.reload()}>Reload?</Button>
            </span>
          ) : null
        }
      </div>
    );
  }

  return (
    <Switch>
      <Route path='/' component={Home} />
      <Route path='/apply' component={Apply} />
      <Route path='/account/apply' component={Apply} />
      <Route path='/availability/survey/:scheduleSurveyId/:evUserId' component={AvailabilitySurvey} />
      <Route path='/account/createcredentials/:evUserId' component={Registration} />

      <Route path="/login" component={Login} />
      <Route path="/login/forgot" component={ForgotPassword} />
      <Route path="/login/reset" component={ResetPassword} />

      <Route path="/account/resetpassword">
        <Redirect to="/login/forgot" />
      </Route>

      <Route path="/home" component={protectRoute(Home)}/>
      <Route path='/profile' component={protectRoute(Profile)} />
      <Route path='/news' component={protectRoute(News)}/>
      <Route path='/documents' component={protectRoute(Documents)}/>
      <Route path='/work_schedule/:viewName?' component={protectRoute(WorkSchedules)} />
      <Route path="/classes/required" component={protectRoute(RequiredClasses)} />
      <Route path="/classes/scheduled" component={protectRoute(ScheduledClasses)} />
      <Route path='/schedule/timeclock' component={protectRoute(PollworkerTimeclock)} />

      <Route>
        <div className='text-center my-40'>
          <h1 className="font-bold text-gray-700" style={{fontSize: '5em'}}>Uh oh!</h1>
          <SectionSubHeading>Hmmm... not sure about that. 404.</SectionSubHeading>
          <br />
          <SectionSubSubHeading>
            <Link to={loggedIn ? '/news' : '/'}>
              ← Head back
            </Link>
          </SectionSubSubHeading>
        </div>
      </Route>
    </Switch>
  );
}
